import { Card, CardBody, Heading, Stack, Text } from '@chakra-ui/react';

type Props = {
  instructions: string;
};

export const InstructionsCard = ({ instructions }: Props) => {
  return (
    <Card h="auto">
      <CardBody p="4">
        <Stack spacing="3">
          <Heading variant="h5">Instructions</Heading>
          <Text textStyle="bodyRegular" whiteSpace="pre-wrap">
            {instructions}
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};
