import {
  Button,
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { CargoType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { getPrettyCargoType, prettyNumber } from '@pelicargo/utils';
import { HiPlus } from 'react-icons/hi2';

import { LabeledValue } from '../LabeledValue';

type Props = {
  cargoType: CargoType;
  // we converted dropoffBy to a string using prettyDate function in a customer screen
  dropoffBy?: string;
  grossWeight: number;
  volumetricWeight: number;
  chargeableWeight: number;
  categories: string[];
  referenceNumber?: string;
  onOpen: () => void;
};

export const CargoInfoCard = ({
  cargoType,
  dropoffBy,
  grossWeight,
  volumetricWeight,
  chargeableWeight,
  categories,
  referenceNumber,
  onOpen,
}: Props) => {
  const prettyCargoType = getPrettyCargoType(cargoType);

  return (
    <Card>
      <CardBody>
        <Stack spacing="6">
          <Stack
            direction="row"
            align="center"
            justify="space-between"
            wrap="wrap"
          >
            <Heading size="md">Cargo Info</Heading>
            <Show if={!!referenceNumber}>
              <Text fontSize="sm">
                Your Reference # <strong>{referenceNumber}</strong>
              </Text>
            </Show>
          </Stack>
          <SimpleGrid columns={{ base: 2, xl: 4 }} gap="4">
            <LabeledValue label="Cargo Type" value={prettyCargoType} />
            <LabeledValue
              label="Gross weight"
              value={`${prettyNumber(grossWeight)} kg`}
            />
            <LabeledValue
              label="Vol. weight"
              value={`${prettyNumber(volumetricWeight)} kg`}
            />
            <LabeledValue
              label="Ch. weight"
              value={`${prettyNumber(chargeableWeight)} kg`}
            />
          </SimpleGrid>

          <Stack
            direction="row"
            align="center"
            justify="space-between"
            wrap="wrap"
            spacing="4"
          >
            <Wrap>
              {categories?.map((item) => (
                <WrapItem key={item}>
                  <Tag size="lg">{item}</Tag>
                </WrapItem>
              ))}
            </Wrap>
            <Button leftIcon={<HiPlus />} onClick={onOpen}>
              View details
            </Button>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};
