import {
  Button,
  Card,
  CardBody,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Cargo, CargoType, DimensionUnit, WeightUnit } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  getHandlingAndPacking,
  getPrettyCargoType,
  getPrettyDimensionUnit,
  getPrettyWeightUnit,
  prettyNumber,
} from '@pelicargo/utils';
import { useState } from 'react';

import { LabeledValue } from '../LabeledValue';

type Props = {
  cargoType: CargoType;
  isKnownShipper: boolean;
  commodity: string;
  grossWeight: number;
  volumetricWeight: number;
  tenderDate: string;
  cargo?: Cargo[];
  dimensionUnit: DimensionUnit;
  weightUnit: WeightUnit;
};

export const RequestDetailsCard = ({
  cargoType,
  isKnownShipper,
  commodity,
  grossWeight,
  volumetricWeight,
  tenderDate,
  cargo = [],
  dimensionUnit,
  weightUnit,
}: Props) => {
  const [showCargo, setShowCargo] = useState(false);

  const handleToggle = () => {
    setShowCargo(!showCargo);
  };

  const renderCargoItem = (item: Cargo) => {
    return (
      <Stack key={item?.id} direction="row" align="center" spacing="4">
        <Text textStyle="captionRegular">{item?.quantity} Pcs</Text>
        <Divider
          orientation="vertical"
          h="4"
          borderWidth="1px"
          borderColor="border.default"
        />
        <Text textStyle="captionRegular">
          {prettyNumber(item?.length)}x{prettyNumber(item?.width)}x
          {prettyNumber(item?.height)} {getPrettyDimensionUnit(dimensionUnit)}
        </Text>
        <Divider
          orientation="vertical"
          h="4"
          borderWidth="1px"
          borderColor="border.default"
        />
        <Text textStyle="captionRegular">
          {prettyNumber(item?.weight)} {getPrettyWeightUnit(weightUnit)}
        </Text>
        <Divider
          orientation="vertical"
          h="4"
          borderWidth="1px"
          borderColor="border.default"
        />
        <Text textStyle="captionRegular">{getHandlingAndPacking(item)}</Text>
      </Stack>
    );
  };

  return (
    <Card h="auto">
      <CardBody p="4">
        <Stack spacing="4">
          <Heading variant="h5">Request Details</Heading>
          <Stack direction="row" justify="flex-start" wrap="wrap" spacing="6">
            <LabeledValue
              label="Cargo Type"
              value={getPrettyCargoType(cargoType)}
            />
            <LabeledValue label="Commodity" value={commodity} />
            <LabeledValue
              label="Weight"
              value={`Gross ${prettyNumber(grossWeight)} kg | Vol ${prettyNumber(volumetricWeight)} kg`}
            />
            <LabeledValue
              label="Known Shipper"
              value={isKnownShipper ? 'Yes' : 'No'}
            />
            <LabeledValue label="Tender" value={tenderDate} />
          </Stack>
          <Stack>
            <Text textStyle="label">Cargo</Text>
            {cargo
              ?.slice(0, showCargo ? cargo?.length : 3)
              ?.map(renderCargoItem)}
            <Show if={cargo?.length > 3}>
              <Button variant="outline" onClick={handleToggle}>
                {showCargo ? 'View less' : 'View more'}
              </Button>
            </Show>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};
