import { OnboardingStep } from '@pelicargo/types';
import * as Sentry from '@sentry/react';
import { isEmpty, isEqual } from 'lodash';
import { createContext, ReactNode, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { trpc, TrpcOutputs } from '../config/trpc';
import { useTargetAccount } from '../hooks/useTargetAccount';
import { supabaseClient } from '../utils/supabaseClient';

type User = NonNullable<TrpcOutputs['currentUser']>;

interface Context {
  isLoggedIn: boolean;
  token?: string;
  isSuperAdmin: boolean;
  user: User;
  isLoading?: boolean;
  logout: () => void;
  refreshUser: () => void;
}

export const AuthContext = createContext({} as Context);

interface Props {
  children: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { targetAccount, changeTargetAccount } = useTargetAccount();

  const [token, setToken] = useLocalStorage<string>('accessToken', '');
  const [user, setUser] = useLocalStorage<User>('user', null);

  const { data, refetch } = trpc.currentUser.useQuery(null, {
    refetchOnWindowFocus: false,
    enabled: !!token,
  });

  useEffect(() => {
    const preferredAccount = data?.preferredAccount;
    const firstAccount = data?.accounts?.[0];
    const nextTargetAccount = preferredAccount || firstAccount;
    const hasCompletedOnboarding =
      user?.onboarding_step === OnboardingStep.SIGNATURE ||
      user?.onboarding_step === OnboardingStep.COMPLETE;

    if (isEmpty(targetAccount) && hasCompletedOnboarding) {
      changeTargetAccount({
        id: nextTargetAccount?.id,
        name: nextTargetAccount?.name,
        iata_number: nextTargetAccount?.iata_number,
        is_iata_number_verified: nextTargetAccount?.is_iata_number_verified,
      });
    }
  }, [
    changeTargetAccount,
    data?.accounts,
    data?.preferredAccount,
    targetAccount,
    targetAccount?.id,
    user?.onboarding_step,
  ]);

  useEffect(() => {
    const nextTargetAccount = data?.accounts?.find(
      (n) => n.id === targetAccount?.id,
    );

    const isCurrentTargetAccountUpdated = !isEqual(
      {
        id: targetAccount?.id,
        name: targetAccount?.name,
        iata_number: targetAccount?.iata_number,
        is_iata_number_verified: targetAccount?.is_iata_number_verified,
      },
      {
        id: nextTargetAccount?.id,
        name: nextTargetAccount?.name,
        iata_number: nextTargetAccount?.iata_number,
        is_iata_number_verified: nextTargetAccount?.is_iata_number_verified,
      },
    );

    if (isCurrentTargetAccountUpdated && nextTargetAccount?.id) {
      changeTargetAccount({
        id: nextTargetAccount?.id,
        name: nextTargetAccount?.name,
        iata_number: nextTargetAccount?.iata_number,
        is_iata_number_verified: nextTargetAccount?.is_iata_number_verified,
      });
    }
  }, [
    changeTargetAccount,
    data?.accounts,
    data?.preferredAccount,
    targetAccount,
    targetAccount?.id,
  ]);

  useEffect(() => {
    if (!token) {
      setUser(null);
    }
    if (!!data && !!token) {
      setUser(data);
    }
  }, [data, setUser, token]);

  const handleLogout = useCallback(async () => {
    try {
      setToken(null);
      setUser(null);
      Sentry.setUser(null);
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }, [navigate, setToken, setUser]);

  useEffect(() => {
    const { data: listener } = supabaseClient.auth.onAuthStateChange(
      (event, session) => {
        if (event === 'SIGNED_IN') {
          setToken(session?.access_token);
        }
        if (event === 'SIGNED_OUT') {
          handleLogout();
        }
      },
    );

    return () => listener.subscription.unsubscribe();
  }, [handleLogout, setToken, setUser]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        user,
        token,
        isSuperAdmin: user?.is_admin,
        logout: handleLogout,
        refreshUser: refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
