import { Card, CardBody, Heading, Stack, Text } from '@chakra-ui/react';
import { Address } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { join } from '@pelicargo/utils';

import { LabeledValue } from '../LabeledValue';

type Props = {
  earliestTender: string;
  cutOffDate: string;
  address: Address;
  dropOffNotes: string;
};

export const DropOffCard = ({
  earliestTender,
  cutOffDate,
  address,
  dropOffNotes,
}: Props) => {
  return (
    <Card h="auto">
      <CardBody p="4">
        <Stack spacing="6">
          <Heading variant="h5">Drop-Off</Heading>
          <Stack direction="row" spacing="6">
            <Show if={!!cutOffDate}>
              <LabeledValue label="Cut Off Date" value={cutOffDate} />
            </Show>
            <Show if={!!earliestTender}>
              <LabeledValue label="Earliest Tender" value={earliestTender} />
            </Show>
          </Stack>
          <Stack spacing="1">
            <Text textStyle="label">Address</Text>
            <Stack spacing="1">
              <Text textStyle="bodyRegular">{address?.line_one}</Text>
              <Show if={!!address?.line_two}>
                <Text textStyle="bodyRegular">{address?.line_two}</Text>
              </Show>
              <Text textStyle="bodyRegular">
                {join([
                  address?.city,
                  address?.state,
                  address?.zip_code,
                  address?.country,
                ])}
              </Text>
            </Stack>
          </Stack>
          <Show if={!!dropOffNotes}>
            <LabeledValue
              label="Drop-Off Notes"
              whiteSpace="pre-wrap"
              value={dropOffNotes}
            />
          </Show>
        </Stack>
      </CardBody>
    </Card>
  );
};
