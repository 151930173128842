import { As, Icon, Select, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { quoteTotalCost } from '@pelicargo/utils';
import { sortBy } from 'lodash';
import { ChangeEvent, memo, useEffect, useState } from 'react';

import { API } from '../../utils/apiTypes';
import { QuoteListItem } from './QuoteListItem';

interface ISorting {
  id?: string;
  field: string;
  sort: 'ASC' | 'DESC';
}

type Quote = API.RequestQuote;

export type Props = {
  quotes: Quote[];
  chargeableWeight: API.ChargeableWeight;
  title: string;
  onToggleDetails: (item: any) => void;
  showSort?: boolean;
  icon?: As;
};

export const QuoteList = memo(
  ({
    quotes = [],
    chargeableWeight,
    title,
    onToggleDetails,
    showSort,
    icon,
  }: Props) => {
    const [sorting, setSorting] = useState<ISorting>({
      field: 'total',
      sort: 'ASC',
    });
    const [items, setItems] = useState<Quote[]>(quotes);

    useEffect(() => {
      let tempQuote = quotes;
      if (showSort) {
        switch (sorting.field) {
          case 'total':
            tempQuote = sortBy(quotes, [(quote) => quoteTotalCost(quote)]);
            break;

          case 'ett':
            tempQuote = sortBy(quotes, [
              (quote) => {
                if (quote.estimated_transit_time) {
                  return quote.estimated_transit_time;
                }
              },
            ]);
            break;

          default:
            tempQuote = quotes;
            break;
        }

        if (sorting.sort === 'DESC') {
          tempQuote = tempQuote.reverse();
        }
      }
      setItems(tempQuote);
    }, [showSort, quotes, sorting]);

    const onSelectSorting = (event: ChangeEvent<HTMLSelectElement>) => {
      setSorting({ field: event.target.value, sort: 'ASC' });
    };

    const renderItem = (item: Quote) => {
      return (
        <QuoteListItem
          quote={item}
          chargeableWeight={chargeableWeight}
          flightSegments={item?.flight_path}
          airline={item?.airline}
          key={item?.id}
          onViewDetails={() => onToggleDetails(item)}
        />
      );
    };

    if (!items?.length) return null;

    return (
      <Stack spacing="4">
        <Stack direction="row" align="center" justify="space-between">
          <Stack direction="row" align="center">
            <Show if={!!icon}>
              <Icon as={icon} />
            </Show>
            <Text textStyle="label" fontSize="12px">
              {title}
            </Text>
          </Stack>

          <Show if={showSort}>
            <Stack direction="row" align="center" spacing="4">
              <Text>Sort</Text>
              <Select
                size="md"
                value={sorting.field}
                onChange={onSelectSorting}
              >
                <option value="total">Total</option>
                <option value="ett">Time</option>
              </Select>
            </Stack>
          </Show>
        </Stack>
        <Stack spacing="0">{items?.map(renderItem)}</Stack>
      </Stack>
    );
  },
);
