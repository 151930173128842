import {
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { prettyCurrency, prettyNumber } from '@pelicargo/utils';
import { useMemo } from 'react';

import { API } from '../../utils/apiTypes';

type Props = CardProps & {
  cardHeading: string;
  lineItems?: API.LineItem[];
  requestCw?: number;
  isSelected?: boolean;
  showCW?: boolean;
  numOfRows?: number;
  validUntil?: string;
  onClick?: () => void;
};

export const PriceCard = ({
  cardHeading = '-',
  lineItems,
  requestCw,
  showCW = false,
  isSelected = false,
  numOfRows = 0,
  validUntil = '',
  onClick,
  ...rest
}: Props) => {
  const total = lineItems
    ?.map((item) => Number(item?.cost))
    .reduce((prev: number, curr: number) => prev + curr, 0);

  const blankRowsNeeded = useMemo(() => {
    if (!lineItems?.length) return 0;
    if (numOfRows === 0) return 0;
    return numOfRows - lineItems.length;
  }, [lineItems, numOfRows]);

  const renderLineItem = (item: API.LineItem, index: number) => {
    // The compiler doesn't like when you use API.LineItem.per_unit_type.PERCENTAGE.
    // Need to use the string 'PERCENTAGE' because of this.
    const isPercentage = item?.per_unit_type === 'PERCENTAGE';

    const formattedPerUnitValue = isPercentage
      ? ''
      : `${prettyCurrency(item?.per_unit_value)}/kg`;
    const perUnitValue = item?.per_unit_value ? formattedPerUnitValue : '';

    const formattedChargeableWeight = item?.chargeable_weight
      ? `${prettyNumber(item.chargeable_weight)} kg`
      : '';
    const chargeableWeight =
      item?.per_unit_value && !isPercentage ? formattedChargeableWeight : '';

    return (
      <Tr key={index}>
        <Td lineHeight="1.5" w="full">
          <Tooltip label={item?.label}>
            <Text
              fontWeight="medium"
              color="initial"
              fontSize="md"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {item?.label}
            </Text>
          </Tooltip>
        </Td>
        <Show if={showCW}>
          <Td textAlign="right" fontSize="md" maxW="70px">
            {chargeableWeight}
          </Td>
        </Show>
        <Td textAlign="right" fontSize="md" maxW="97px">
          {perUnitValue}
        </Td>
        <Td textAlign="right" color="initial" fontSize="md" maxW="126px">
          {item.cost ? `${prettyCurrency(item.cost)}` : ''}
        </Td>
      </Tr>
    );
  };

  const renderBlankRow = (index: number) => (
    <Tr key={`blank-${index}`}>
      <Td borderColor="white" lineHeight="1.5">
        <Text opacity={0} fontWeight="medium" color="initial" fontSize="md">
          -
        </Text>
      </Td>
      <Show if={showCW}>
        <Td
          borderColor="white"
          opacity={0}
          textAlign="right"
          fontSize="md"
          maxW="70px"
        >
          -
        </Td>
      </Show>
      <Td
        borderColor="white"
        opacity={0}
        textAlign="right"
        fontSize="md"
        maxW="97px"
      >
        -
      </Td>
      <Td
        borderColor="white"
        opacity={0}
        textAlign="right"
        color="initial"
        fontSize="md"
        maxW="126px"
      >
        -
      </Td>
    </Tr>
  );

  return (
    <Card
      borderColor={isSelected ? 'orange.500' : 'border.default'}
      cursor={onClick ? 'pointer' : 'default'}
      h="initial"
      onClick={onClick}
      display="table"
      maxW="421px"
      minW="fit-content"
      {...rest}
    >
      <CardHeader pb="0" pl="4">
        <Heading variant="h5">{cardHeading}</Heading>
      </CardHeader>
      <CardBody py="4" px="0">
        <Table variant="simple" p="0" size="sm" h="full">
          <Tbody>
            {lineItems?.map(renderLineItem)}
            {Array.from(Array(blankRowsNeeded)).map(renderBlankRow)}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td borderBottom="none" lineHeight="1.5" maxW="126px">
                <Text fontSize="xl" fontWeight="bold" color="initial">
                  Total
                </Text>
              </Td>
              <Show if={showCW}>
                <Td borderBottom="none" maxW="70px" />
              </Show>
              <Td
                fontSize="lg"
                color="text.paragraph"
                w="full"
                textAlign="right"
                borderBottom="none"
                maxW="97"
              >
                <Show if={showCW}>
                  <Text fontSize="lg" w="full" color="text.paragraph">
                    {prettyCurrency(total / requestCw)}
                    /kg
                  </Text>
                </Show>
              </Td>

              <Td textAlign="right" borderBottom="none">
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  textAlign="right"
                  w="full"
                  color="initial"
                  maxW="126px"
                >
                  {total ? prettyCurrency(total) : ''}
                </Text>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
        <Show if={!!validUntil}>
          <Stack align="right" w="full" px="4">
            <Text
              textStyle="caption"
              fontSize="xs"
              textAlign="right"
              color="text.subtle"
            >
              Valid until {validUntil}
            </Text>
          </Stack>
        </Show>
      </CardBody>
    </Card>
  );
};
