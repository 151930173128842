import { Button, Stack, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { OnboardingStep } from '@pelicargo/types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleTagsInput } from '../../components/SimpleTagsInput';
import { trpc } from '../../config/trpc';
import { useOnboarding } from '../../hooks/onboarding/useOnboarding';
import { useScreenView } from '../../hooks/useScreenView';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

type FormValues = {
  emails: string[];
};

const schema = z.object({
  emails: z
    .array(z.string().email())
    .min(1, 'Please add at least one email address to invite.'),
});

export const RegisterInvite = () => {
  const toast = useToast();
  const navigate = useNavigate();
  useScreenView(MixpanelEvent.InviteView);
  const { setOnboardingStep } = useOnboarding();
  const { mutateAsync: sendAccountInvites, isLoading: isSubmitting } =
    trpc.sendAccountInvites.useMutation();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      emails: [],
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      trackEvent(MixpanelEvent.InviteSubmit);
      await sendAccountInvites({ emails: values.emails });
      await setOnboardingStep(OnboardingStep.COMPLETE);
      toast({
        title: 'Invites sent',
        description: 'We sent an invite to your colleague(s)',
        status: 'success',
      });
      navigate('/');
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error.message,
        status: 'error',
      });
    }
  };

  const handleSkip = async () => {
    await setOnboardingStep(OnboardingStep.COMPLETE);
    navigate('/');
  };

  return (
    <Page title="Company" showHeader={false} showFooter={false}>
      <OnboardingLayout
        heading="Invite users"
        subHeading="Your account will be linked to your colleagues so please invite them to work on shipments together."
        step={3}
      >
        <SimpleForm {...methods} onSubmit={handleSubmit}>
          <SimpleTagsInput
            name="emails"
            placeholder="Ex. james@pelicargo.com, jack@pelicargo.com"
          />
          <Stack direction="row" align="center" justify="flex-end" spacing="6">
            <Button
              size="lg"
              colorScheme="gray"
              variant="link"
              onClick={handleSkip}
            >
              Skip this step
            </Button>
            <Button
              size="lg"
              colorScheme="primary"
              type="submit"
              isLoading={isSubmitting}
            >
              Continue
            </Button>
          </Stack>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
