import {
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CargoType } from '@pelicargo/types';
import { getPrettyCargoType, prettyDate, prettyNumber } from '@pelicargo/utils';
import { HiInformationCircle } from 'react-icons/hi2';

import { API } from '../../utils/apiTypes';
import { LabeledValue } from '../LabeledValue';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  origin: string;
  destination: string;
  dropoffBy: string;
  cargoType: CargoType;
  grossWeight: number;
  volumetricWeight: number;
  chargeableWeight: number;
  cargoDetails: string;
  cargo: API.Request['cargo'];
};

export const QuoteListingModal = ({
  isOpen,
  onClose,
  origin,
  destination,
  dropoffBy,
  cargoType,
  grossWeight,
  volumetricWeight,
  chargeableWeight,
  cargoDetails,
  cargo,
}: Props) => {
  const renderCargoItem = (item: API.Request['cargo'][0], index: number) => {
    return (
      <Stack key={index} spacing="4">
        <SimpleGrid columns={{ base: 2, xl: 4 }} gap={4}>
          <LabeledValue
            label="Cargo"
            value={`Cargo ${index + 1}`}
            spacing="0"
          />
          <LabeledValue
            label="Handling"
            value={item?.handling
              ?.toString()
              .replace(/([A-Z])/g, ' $1')
              .trim()}
            spacing="0"
          />
          <LabeledValue
            label="Amount of Pieces"
            value={item?.quantity}
            spacing="0"
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 2, xl: 4 }} gap={4}>
          <LabeledValue label="Length (in)" value={item?.length} spacing="0" />
          <LabeledValue label="Width (in)" value={item?.width} spacing="0" />
          <LabeledValue label="Height (in)" value={item?.height} spacing="0" />
          <LabeledValue label="Weight (kg)" value={item?.weight} spacing="0" />
        </SimpleGrid>
      </Stack>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'lg', lg: '4xl' }}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Heading>Cargo Details</Heading>
        </ModalHeader>
        <ModalBody p="8">
          <Stack spacing="6">
            <SimpleGrid columns={{ base: 2, xl: 4 }} gap={4}>
              <LabeledValue
                label="Route"
                value={`${origin} → ${destination}`}
              />
              <LabeledValue
                label="Expected Drop-Off"
                value={prettyDate(dropoffBy)}
              />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 2, xl: 4 }} gap={4}>
              <LabeledValue
                label="Cargo Type"
                value={getPrettyCargoType(cargoType)}
              />
              <LabeledValue
                label="Gross Weight"
                value={`${prettyNumber(grossWeight)} kg`}
              />
              <LabeledValue
                label="Volumetric Weight"
                value={`${prettyNumber(volumetricWeight)} kg`}
              />
              <LabeledValue
                label="Chargeable Weight"
                value={`${prettyNumber(chargeableWeight)} kg`}
              />
            </SimpleGrid>

            <Stack direction="row" align="center">
              <Heading size="sm">Cargo details</Heading>
              <Text>{cargoDetails}</Text>
            </Stack>

            <Stack spacing="6">{cargo?.map(renderCargoItem)}</Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack
            direction="row"
            align="center"
            spacing="4"
            p="4"
            borderRadius="md"
            bg="gray.800"
          >
            <Icon as={HiInformationCircle} color="white" boxSize="6" />
            <Text fontSize="sm" color="white">
              If you wish to edit/adjust your cargo information, please go back
              to listing. If you've already submitted your booking request,
              please contact Pelicargo through our customer service chatbot.
            </Text>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
