import { trpc, TrpcInputs, TrpcOutputs } from '../../config/trpc';
import { MutateFunction, UseMutateResource } from '../../utils/types';

type AccountInviteInput = TrpcInputs['acceptAccountInvite'];
export type AccountInviteOutput = NonNullable<
  TrpcOutputs['acceptAccountInvite']
>;
type AccountInviteMutateFunction = MutateFunction<
  AccountInviteInput,
  AccountInviteOutput
>;
type UseCreateAccountInvite = UseMutateResource<AccountInviteMutateFunction>;

export const useAcceptAccountInvite = (): UseCreateAccountInvite => {
  const { mutateAsync, isLoading, error } =
    trpc.acceptAccountInvite.useMutation();

  const accept: AccountInviteMutateFunction = async (args) =>
    mutateAsync(args as any);

  return [accept, isLoading, error?.message];
};
