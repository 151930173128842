import {
  DimensionUnit,
  Quote,
  QuoteVariant,
  WeightUnit,
} from '@pelicargo/types';

import { Units } from './constants';

export type CalculateOptions = {
  ceil?: boolean;
};

const defaultOptions: CalculateOptions = { ceil: false };

// Volumetric Weight

type CargoParams = {
  length: number;
  width: number;
  height: number;
  quantity: number;
  weight?: number;
};

export const getVolumetricWeight = (
  { length, width, height, quantity }: CargoParams,
  options?: CalculateOptions,
) => {
  const { ceil } = { ...defaultOptions, ...options };
  const volume = length * width * height;
  const volumetricWeight = volume / 6000;
  const totalVolumetricWeight = volumetricWeight * quantity;

  if (ceil) return Math.ceil(totalVolumetricWeight);
  return totalVolumetricWeight;
};

export const getVolumeCBM = (
  { length, width, height, quantity }: CargoParams,
  options?: CalculateOptions,
) => {
  const { ceil } = { ...defaultOptions, ...options };

  const volumeInCm = length * width * height * quantity;
  const volumeInM = volumeInCm * 1e-6;

  if (ceil) return Math.ceil(volumeInM);
  return volumeInM;
};

// Gross Weight

type GrossWeightParams = CargoParams & { weight?: number };

export const getGrossWeight = (
  { quantity, weight }: GrossWeightParams,
  options?: CalculateOptions,
) => {
  const { ceil } = { ...defaultOptions, ...options };

  const grossWeight = quantity * (weight || 0);

  if (ceil) return Math.ceil(grossWeight);
  return grossWeight;
};

export const lbToKg = (lb: number) => lb * 0.45359237;
export const kgToLb = (kg: number) => kg * 2.20462262185;
export const cmToIn = (cm: number) => cm * 0.393700787;
export const inchToCm = (inch: number) => inch * 2.54;

export const ensureMetricCargo = (
  cargo: CargoParams,
  { weightUnit, dimensionUnit }: Units,
) => {
  const { weight, length, width, height } = cargo;

  const nextCargo = { ...cargo };

  if (weight) {
    const isKg = weightUnit === WeightUnit.kg;
    if (!isKg) nextCargo.weight = lbToKg(weight);
  }

  const isCm = dimensionUnit === DimensionUnit.cm;
  if (!isCm) {
    nextCargo.length = inchToCm(length);
    nextCargo.width = inchToCm(width);
    nextCargo.height = inchToCm(height);
  }

  // If we originally had the cargo weight as null, we want to keep it null instead of NaN
  if (cargo?.weight === null) nextCargo.weight = undefined;

  return nextCargo;
};

export const quoteTotalCost = (
  quote: Quote & { quote_variants: QuoteVariant[] },
) => {
  if (!quote?.quote_variants?.length) return;

  const quoteVariantTotalCost = quote.quote_variants.map((quoteVariant) => {
    if (!quoteVariant?.line_items?.length) return 0;
    return quoteVariant.line_items.reduce((a, b) => a + b?.cost, 0);
  });

  return Math.min(...quoteVariantTotalCost);
};
