import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tag,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { RequestStatus, SelectOption } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { prettyNumber } from '@pelicargo/utils';
import { formatAwb } from '@pelicargo/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import { CancelBookingModal } from '../../components/CancelBookingModal';
import { DataTable } from '../../components/DataTable';
import { Page } from '../../components/Page';
import { Select } from '../../components/Select';
import { UpdateTenderDateModal } from '../../components/UpdateTenderDateModal';
import { useAccountAirports } from '../../hooks/airports/useAccountAirports';
import { useRequests } from '../../hooks/request/useRequests';
import { useUpdateCustomerRef } from '../../hooks/request/useUpdateCustomerRef';
import { useTargetAccount } from '../../hooks/useTargetAccount';
import {
  formatCargoQuantity,
  formatDestinationAirports,
  formatOriginAirports,
  translateRequestStatusFromOptions,
} from '../../utils/helpers';

const TAKE_PER_PAGE = 50;

const statusColorScheme = {
  [RequestStatus.New]: 'orange',
  [RequestStatus.QuotingInProgress]: 'orange',
  [RequestStatus.QuotingPending]: 'orange',
  [RequestStatus.QuotingComplete]: 'orange',
  [RequestStatus.InfoPending]: 'orange',
  [RequestStatus.BookingRequested]: 'blue',
  [RequestStatus.BookingChange]: 'blue',
  [RequestStatus.BookingPending]: 'blue',
  [RequestStatus.BookingComplete]: 'blue',
  [RequestStatus.BookingCancel]: 'red',
  [RequestStatus.Cancelled]: 'red',
};

const customerRequestStatus = {
  [RequestStatus.New]: 'In Progress',
  [RequestStatus.QuotingInProgress]: 'In Progress',
  [RequestStatus.QuotingPending]: 'In Progress',
  [RequestStatus.QuotingComplete]: 'In Progress',
  [RequestStatus.InfoPending]: 'In Progress',
  [RequestStatus.BookingRequested]: 'Booked',
  [RequestStatus.BookingChange]: 'Booked',
  [RequestStatus.BookingPending]: 'Booked',
  [RequestStatus.BookingComplete]: 'Booked',
  [RequestStatus.BookingCancel]: 'Cancelled',
  [RequestStatus.Cancelled]: 'Cancelled',
};

const customerStatusOptions = [
  {
    label: 'In-Progress',
    value: 'InProgress',
  },
  {
    label: 'Booked',
    value: 'Booked',
  },
  {
    label: 'Cancelled',
    value: 'Cancelled',
  },
];

type ModalState = {
  openModal: 'changeTenderDate' | 'cancelBooking';
  requestId: number;
};

export const Requests = () => {
  const toast = useToast({ size: 'sm' });
  const { targetAccount } = useTargetAccount();

  const [modalState, setModalState] = useState<ModalState | null>(null);
  const [query, setQuery] = useState<string>();
  const [statuses, setStatuses] = useState([]);
  const [origins, setOrigins] = useState<string[]>();
  const [destinations, setDestinations] = useState<string[]>();
  const { data: airports, isFetching: isFetchingAirports } =
    useAccountAirports();
  const [take, setTake] = useState<number>(TAKE_PER_PAGE);
  const [updateCustomerRef, isUpdating] = useUpdateCustomerRef();

  const debouncedSearch = useDebounce<string>(query, 500);

  const { data, isFetching, isLoading, isRefetching, refetch } = useRequests({
    statuses,
    take,
    skip: 0,
    origins,
    destinations,
    search: debouncedSearch,
  });
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
    // This should only run when the target account changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetAccount?.id]);

  const handleRequestStatusSelect = (values: SelectOption[]) => {
    setStatuses(translateRequestStatusFromOptions(values));
  };

  const handleOrigins = (options: SelectOption[]) => {
    setOrigins(options.map((o) => o.value));
  };

  const handleDestinations = (options: SelectOption[]) => {
    setDestinations(options.map((d) => d.value));
  };

  const updateRequest = useCallback(
    (requestId: number) => async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        const value = event.target.value;
        await updateCustomerRef({ requestId, customerRef: value });

        toast({
          title: 'Reference number updated',
          status: 'success',
        });

        await refetch();
      } catch (error) {
        console.log(error);
        toast({
          title: error.message,
          status: 'error',
        });
      }
    },
    [refetch, toast, updateCustomerRef],
  );

  const handleLoadMore = () => setTake((prev) => prev + TAKE_PER_PAGE);
  const displayLoadMore = data?.requests?.length >= take;

  const originOptions = airports?.origins.map((node) => ({
    label: node,
    value: node,
  }));

  const destinationOptions = airports?.destinations.map((node) => ({
    label: node,
    value: node,
  }));

  const handleOpenModal =
    (modal: 'changeTenderDate' | 'cancelBooking', requestId: number) => () => {
      setModalState({ openModal: modal, requestId });
    };

  const handleCloseModal = () => setModalState(null);

  const tableData = useMemo(() => {
    return data?.requests?.map((request) => ({
      id: request?.id,
      ref: `R${request?.id}`,
      toUnit: formatOriginAirports(request?.origin_airports),
      destination: formatDestinationAirports(request?.destination_airports),
      pieces: formatCargoQuantity(request?.cargo),
      gross: prettyNumber(request?.gross_weight),
      chargeable: prettyNumber(request?.chargeable_weight),
      status: request?.status,
      awb: formatAwb(request?.awb_number),
      tracking: request?.customer_ref,
      bookedQuoteVariantId: request?.booked_quote_variant?.quote_id,
    }));
  }, [data?.requests]);

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor('id', {
      header: '',
      enableSorting: false,
      cell: (cell) => {
        const bookedQuoteVariantId = cell.row.original['bookedQuoteVariantId'];
        const requestId = cell.getValue();
        const status = cell.row.getValue('status');
        const isCancelled =
          status === RequestStatus.Cancelled ||
          status === RequestStatus.BookingCancel;
        const isDisabled = !bookedQuoteVariantId || isCancelled;
        const label = isCancelled
          ? 'The booking was cancelled.'
          : 'There are no booked quotes yet.';
        return (
          <ButtonGroup spacing={3}>
            <Button
              as={Link}
              size="sm"
              colorScheme="primary"
              to={`/requests/${requestId}`}
            >
              Quotes
            </Button>
            <Tooltip isDisabled={!isDisabled} label={label}>
              <Box>
                <Button
                  size="sm"
                  colorScheme="secondary"
                  isDisabled={isDisabled}
                  onClick={() =>
                    navigate(
                      `/requests/${requestId}/quotes/${bookedQuoteVariantId}`,
                    )
                  }
                >
                  Booking
                </Button>
              </Box>
            </Tooltip>
            <Menu isLazy>
              <MenuButton
                as={Button}
                colorScheme="gray"
                size="sm"
                isDisabled={isDisabled}
                rightIcon={<HiChevronDown />}
              >
                Modify
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={handleOpenModal(
                    'changeTenderDate',
                    requestId as number,
                  )}
                >
                  Change Tender Date
                </MenuItem>
                <MenuItem
                  onClick={handleOpenModal(
                    'cancelBooking',
                    requestId as number,
                  )}
                >
                  Cancel Booking
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>
        );
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => {
        const status = info.getValue();

        return (
          <Tag w="fit-content" colorScheme={statusColorScheme[status]}>
            {customerRequestStatus[status]}
          </Tag>
        );
      },
    }),
    columnHelper.accessor('ref', {
      header: 'Request #',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('toUnit', {
      header: 'Origin',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('destination', {
      header: 'Dest',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('pieces', {
      header: '# Pieces',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('chargeable', {
      header: 'Chargeable (kg)',
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor('awb', {
      header: 'AWB #',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('tracking', {
      header: 'Reference #',
      cell: useCallback(
        (info) => {
          const allCells = info?.row?.getAllCells();
          const value = info.getValue();
          const index = info.row.index;
          const idRef = `${index}_id`;

          const id = allCells.find((n) => n?.id === idRef)?.getValue();

          return (
            <Editable defaultValue={value} isDisabled={isUpdating} w="full">
              <EditablePreview w="full" />
              <EditableInput w="full" onBlur={updateRequest(Number(id))} />
            </Editable>
          );
        },
        [isUpdating, updateRequest],
      ),
    }),
  ];

  const isLoadingRequests =
    isFetching || isLoading || isRefetching || isFetchingAirports || isUpdating;

  return (
    <Page title="Requests" flex="1">
      <Container maxW="container.2xl" px={{ base: '4', lg: '16' }} py="10">
        <Stack minH="full" w="full" spacing="6">
          <Heading variant="h3">Requests</Heading>
          <Stack direction={{ base: 'column', md: 'row' }} w="full" spacing="6">
            <HStack w="full">
              <Input
                placeholder="Search"
                size="lg"
                minH={{ base: '60px', md: 'auto' }}
                h={{ base: undefined, md: 'full' }}
                borderRadius="lg"
                bgColor="bg.surface"
                borderColor="border.default"
                onChange={(x) => setQuery(x.target.value)}
              />
            </HStack>

            <HStack w="full">
              <Select
                label="Origin"
                options={originOptions}
                onChange={handleOrigins}
              />
            </HStack>
            <HStack w="full">
              <Select
                label="Destination"
                options={destinationOptions}
                onChange={handleDestinations}
              />
            </HStack>
            <HStack w="full">
              <Select
                label="Status"
                options={customerStatusOptions}
                onChange={handleRequestStatusSelect}
              />
            </HStack>
          </Stack>
          <Show if={data?.requests?.length > 0}>
            <DataTable columns={columns} data={tableData} />
          </Show>
          <Show if={isLoadingRequests}>
            <Center flex={1} py={16}>
              <Spinner />
            </Center>
          </Show>
          <Show if={displayLoadMore}>
            <Stack alignItems="center">
              <Button onClick={handleLoadMore}>Load More</Button>
            </Stack>
          </Show>
        </Stack>
      </Container>
      <Show if={modalState?.openModal === 'changeTenderDate'}>
        <UpdateTenderDateModal
          requestId={modalState?.requestId}
          refetch={refetch}
          isOpen={modalState?.openModal === 'changeTenderDate'}
          onClose={handleCloseModal}
        />
      </Show>
      <Show if={modalState?.openModal === 'cancelBooking'}>
        <CancelBookingModal
          requestId={modalState?.requestId}
          refetch={refetch}
          isOpen={modalState?.openModal === 'cancelBooking'}
          onClose={handleCloseModal}
        />
      </Show>
    </Page>
  );
};
