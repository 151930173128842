import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CargoType, DocumentType, Quote, QuoteVariant } from '@pelicargo/types';
import { TimeOfDay, VariantType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { getPrettyCargoType, removeDash } from '@pelicargo/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { HiOutlineExclamation } from 'react-icons/hi';
import { HiOutlineChatBubbleLeft } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { trpc } from '../config/trpc';
import { useFlightData } from '../hooks/useFlightData';
import { formatAwbNumber } from '../utils/inputFormatters';
import { BacklogStatus } from './BacklogStatus';
import { Hint } from './Hint';
import { LabeledValue } from './LabeledValue';
import { PriceCard } from './PriceCard';
import { SimpleCheckbox } from './SimpleCheckbox';
import { SimpleDatePickerInput } from './SimpleDatePickerInput';
import { SimpleDropzone } from './SimpleDropzone';
import { SimpleForm } from './SimpleForm';
import { SimpleInput } from './SimpleInput';
import { SimpleTextarea } from './SimpleTextarea';
import { Timeline } from './Timeline';

type FormValues = {
  terms: boolean;
  awb_number: string;
  tender_date: Date;
  customer_ref: string;
  additional_information: string;
  tender_date_time_of_day: TimeOfDay;
  // TODO: fix these typings
  documents: Document[];
};

const schema = z
  .object({
    terms: z.boolean().refine((val) => val === true, {
      message: 'Please accept the terms and conditions',
    }),
    awb_number: z
      .string()
      .nullable()
      .refine(
        (value) => {
          const cleanedValue = removeDash(value);
          // Check if the field is empty or has only the pre-filled digits
          if (!cleanedValue || cleanedValue?.length === 3) return true;
          // Check if the length is exactly 11 characters
          return cleanedValue?.length === 11;
        },
        {
          message: 'AWB number must be exactly 11 characters',
        },
      ),
    tender_date: z.coerce.date().refine((date) => date instanceof Date, {
      message: 'Tender date is required',
    }),
    tender_date_time_of_day: z.nativeEnum(TimeOfDay).nullish(),
    customer_ref: z.string().optional(),
    additional_information: z.string().optional(),
  })
  .refine(
    (data) => {
      if (
        data.cargoType === CargoType.DangerousGoods &&
        (!data.documents || data.documents.length < 1)
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Please upload a document for dangerous goods',
      path: ['documents'], // This specifies where the error should be attached in the case of a conditional validation
    },
  );

type Props = {
  isOpen: boolean;
  onClose: () => void;
  flightPath: number;
  quote: Quote;
  request: any;
  validity: number | null;
  effectiveRate: string | null;
  selectedQuoteVariant: any;
  setSelectedQuoteVariant: (id: any) => void;
  handleSupport: () => void;
};

export const BookingDrawer = ({
  isOpen,
  onClose,
  flightPath,
  request,
  quote,
  effectiveRate,
  validity,
  selectedQuoteVariant,
  setSelectedQuoteVariant,
  handleSupport,
}: Props) => {
  const navigate = useNavigate();
  const toast = useToast({
    duration: 5000,
    isClosable: true,
  });
  const bookQuote = trpc.bookQuote.useMutation();

  const { flightData, shouldShowFlightData } = useFlightData(
    quote?.flight_path,
  );

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const documentsInForm = methods.watch('documents');

  useEffect(() => {
    methods.reset({
      ...methods.getValues(),
      customer_ref: request?.customer_ref ?? '',
      awb_number: request?.awb_number ?? '',
      documents: request?.documents ?? [],
    });
  }, [methods, request?.customer_ref, request?.awb_number, request?.documents]);

  const shouldShowDgdWarning = useMemo(() => {
    const isDgd = request?.cargo_type === CargoType.DangerousGoods;
    const hasDocument = documentsInForm?.length > 0;

    if (isDgd && !hasDocument) return true;
    return false;
  }, [documentsInForm, request?.cargo_type]);

  const quoteVariants = useMemo(() => {
    const order = [
      VariantType.General,
      VariantType.Expedited,
      VariantType.Urgent,
      VariantType.Other,
    ];
    return quote?.quote_variants?.sort(
      (a, b) => order.indexOf(a.type) - order.indexOf(b.type),
    );
  }, [quote?.quote_variants]);

  const longestNumOfRows = useMemo(() => {
    return quoteVariants?.sort((a, b) => {
      return b?.line_items?.length - a?.line_items?.length;
    })?.[0]?.line_items?.length;
  }, [quoteVariants]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const normalizedAwb =
          values.awb_number?.length > 3 ? removeDash(values.awb_number) : null;

        const quoteVariant = quote?.quote_variants?.find(
          (quoteVariant) => quoteVariant?.id === Number(selectedQuoteVariant),
        );

        if (!quoteVariant) {
          return toast({
            status: 'error',
            title: 'Error',
            description: 'Please select a quote variant',
          });
        }

        await bookQuote.mutateAsync({
          id: quote?.id,
          requestId: request?.id,
          awbNumber: normalizedAwb,
          customerRef: values.customer_ref,
          additionalInformation: values.additional_information,
          tenderDate: values.tender_date,
          selectedService: quoteVariant?.type,
          tenderDateTimeOfDay: values.tender_date_time_of_day,
        });

        navigate(`/requests/${request?.id}/quotes/${quote?.id}`);
      } catch (error) {
        toast({
          status: 'error',
          title: 'Error',
          description: error.message,
        });
      }
    },
    [
      bookQuote,
      toast,
      navigate,
      quote?.id,
      quote?.quote_variants,
      request?.id,
      selectedQuoteVariant,
    ],
  );

  const renderQuoteVariant = useCallback(
    (quoteVariant: QuoteVariant) => {
      const isSelected = selectedQuoteVariant === quoteVariant?.id;

      return (
        <PriceCard
          key={quoteVariant?.id}
          cardHeading={`${quoteVariant?.name} (${quoteVariant?.type})` || ''}
          lineItems={quoteVariant?.line_items}
          isSelected={isSelected}
          requestCw={request?.chargeable_weight}
          onClick={() => setSelectedQuoteVariant(quoteVariant?.id)}
          showCW
          numOfRows={longestNumOfRows}
        />
      );
    },
    [
      selectedQuoteVariant,
      request?.chargeable_weight,
      longestNumOfRows,
      setSelectedQuoteVariant,
    ],
  );

  return (
    <Drawer
      placement="right"
      size={{ base: 'full', lg: 'xl' }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent maxH="unset" overflow="auto">
        <DrawerCloseButton onClick={onClose} />
        <DrawerHeader pt="8">
          <Stack spacing="4">
            <Stack direction="row" align="center" justify="space-between">
              <Stack direction="row" align="center" spacing="4">
                <Image src={quote?.airline?.logo_uri} height={70} width={70} />

                <Stack>
                  <Heading size="lg">{quote?.airline?.name}</Heading>

                  <Stack direction="row">
                    <Show if={flightPath === 0}>
                      <Tag
                        variant="outline"
                        colorScheme="teal"
                        fontWeight="bold"
                        size="md"
                      >
                        Direct
                      </Tag>
                    </Show>
                    <Show if={flightPath >= 1}>
                      <Tag
                        variant="outline"
                        colorScheme="orange"
                        fontWeight="bold"
                        size="md"
                      >
                        {flightPath} {flightPath === 1 ? 'stop' : 'stops'}
                      </Tag>
                    </Show>
                    <BacklogStatus
                      backlogStatus={quote?.backlog_status}
                      backloggedUntil={quote?.backlogged_until}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Button
                leftIcon={<HiOutlineChatBubbleLeft />}
                onClick={handleSupport}
              >
                Contact Support
              </Button>
            </Stack>
            <Stack direction="row" justify="space-between" spacing="8">
              <LabeledValue
                label="Rate (kg)"
                value={effectiveRate ? `${effectiveRate}/kg` : '-'}
              />
              <LabeledValue
                label="Cargo Type"
                value={getPrettyCargoType(request?.cargo_type) || '-'}
              />
              <LabeledValue
                label="Validity"
                value={
                  validity
                    ? validity > 0
                      ? `${validity} ${validity > 1 ? 'Days' : 'Day'}`
                      : 'Expired'
                    : '-'
                }
              />
              <LabeledValue
                label="Estimated Transit"
                value={quote?.estimated_transit_time ?? '-'}
              />
            </Stack>

            <Divider />
          </Stack>
        </DrawerHeader>
        <DrawerBody overflow="unset" pb="8">
          <Show if={shouldShowFlightData}>
            <Timeline data={flightData} />
          </Show>
          <Hint name="quoteListing-quoteVariants">
            <Stack direction="row" overflowX="auto" pt="4" spacing="4">
              {quoteVariants?.map(renderQuoteVariant)}
            </Stack>
          </Hint>
        </DrawerBody>
        <DrawerFooter
          w="full"
          shadow="0 -2px 20px 10px rgba(0,0,0,0.05)"
          py="8"
        >
          <SimpleForm onSubmit={handleSubmit} {...(methods as any)}>
            <Stack w="full" spacing="4">
              <HStack>
                <SimpleCheckbox name="terms" variant="unstyled">
                  <Text>
                    By checking, I accept the airline's conditions of contract.
                  </Text>
                </SimpleCheckbox>
                <Box color="orange.500" fontWeight="600">
                  <Link
                    to="/terms"
                    target="_blank"
                    rel="noreferrer"
                    fontWeight="bold"
                  >
                    Learn More
                  </Link>
                </Box>
              </HStack>

              <Stack direction={{ base: 'column', lg: 'row' }} w="full">
                <SimpleInput
                  label="AWB #"
                  name="awb_number"
                  placeholder="x x x | x x x x x x x x"
                  maxLength={12}
                  variant="outline"
                  w="full"
                  formatter={formatAwbNumber}
                />
                <SimpleDatePickerInput
                  name="tender_date"
                  label="Tender Date *"
                  variant="outline"
                  matchWidth={false}
                  timeOfDay
                  timeOfDayName="tender_date_time_of_day"
                  iconFontSize="20px"
                />
                <SimpleInput
                  label="Your Reference #"
                  name="customer_ref"
                  placeholder="x x x   x x x   x x x"
                  variant="outline"
                  w="full"
                />
              </Stack>
              <SimpleTextarea
                name="additional_information"
                label="Additional Notes"
                placeholder="Additional notes"
                variant="outline"
                rows={2}
              />
              <Show if={shouldShowDgdWarning}>
                <Flex>
                  <Tag
                    size="lg"
                    borderRadius="full"
                    variant="outline"
                    colorScheme="red"
                    py="2"
                  >
                    <TagLeftIcon as={HiOutlineExclamation} boxSize="6" />
                    <TagLabel fontSize="lg">
                      Missing Dangerous Goods Declaration - Upload to Book
                    </TagLabel>
                  </Tag>
                </Flex>
              </Show>
              <Show if={request?.cargo_type === CargoType.DangerousGoods}>
                <SimpleDropzone
                  name="documents"
                  documentType={DocumentType.DGD}
                  requestId={request?.id}
                />
              </Show>
              <Button
                colorScheme="primary"
                size="xl"
                type="submit"
                isLoading={bookQuote.isLoading}
                isDisabled={shouldShowDgdWarning || bookQuote.isLoading}
              >
                Book
              </Button>
            </Stack>
          </SimpleForm>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
