import {
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';

import { AuthLayout } from '../../components/AuthLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimplePasswordInput } from '../../components/SimplePasswordInput';
import { supabaseClient } from '../../utils/supabaseClient';

type FormValues = {
  email: string;
  password: string;
};

const schema = z.object({
  email: z.string({ required_error: 'Email is required' }).email(),
  password: z.string({ required_error: 'Password is required' }),
});

export const Login = () => {
  const navigate = useNavigate();
  const toast = useToast({
    position: 'bottom',
    size: 'sm',
    duration: 3000,
    isClosable: true,
  });
  const [onboardingEmail] = useLocalStorage('onboardingEmail', null);

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      email: onboardingEmail,
      password: '',
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRequestMagicLink = async () => {
    try {
      const email = methods?.getValues('email');
      const { error } = await supabaseClient.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: false,
          emailRedirectTo: window.location.origin,
        },
      });

      if (error) {
        if (error.message === 'unsupported_otp_type') {
          throw new Error('Please use a valid email.');
        }
        if (error.message === 'Signups not allowed for otp') {
          navigate('/register');
          return;
        }
        throw new Error(error?.message);
      }

      toast({
        title: 'Magic Link Sent',
        description: 'Follow the link in your inbox.',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
      });
    }
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsSubmitting(true);

      const { error } = await supabaseClient.auth.signInWithPassword(values);
      if (error) {
        throw new Error(error?.message);
      }

      navigate('/');
    } catch (error) {
      toast({
        title: error?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page title="Login" showHeader={false} showFooter={false}>
      <AuthLayout heading="Log in">
        <SimpleForm
          onSubmit={handleSubmit}
          {...(methods as UseFormReturn<FormValues>)}
        >
          <Stack spacing="4">
            <SimpleInput
              name="email"
              label="Email Address"
              placeholder="Email"
              variant="outline"
            />

            <SimplePasswordInput
              name="password"
              label="Password"
              placeholder="Password"
              variant="outline"
            />
            <Button
              as={Link}
              to="/forgot"
              aria-label="Forgot Password"
              variant="link"
              colorScheme="primary"
              alignSelf="flex-end"
            >
              Forgot password?
            </Button>

            <Button
              colorScheme="primary"
              size="lg"
              type="submit"
              isLoading={isSubmitting}
            >
              Log in
            </Button>
          </Stack>
        </SimpleForm>
        <HStack>
          <Divider />
          <Text textStyle="sm" whiteSpace="nowrap" color="text.subtle">
            or
          </Text>
          <Divider />
        </HStack>
        <Stack>
          <Button colorScheme="gray" onClick={handleRequestMagicLink}>
            Email me a login link
          </Button>
          <Stack direction="row" align="center" justify="center" wrap="wrap">
            <Text fontSize="md">Don't have an account?</Text>
            <Link to="/register">
              <Button variant="link" colorScheme="primary">
                Create one now
              </Button>
            </Link>
          </Stack>
        </Stack>
      </AuthLayout>
    </Page>
  );
};
