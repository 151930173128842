import { OnboardingStep } from '@pelicargo/types';
import { useNavigate } from 'react-router-dom';

import { trpc } from '../../config/trpc';
import { getRouteForOnboardingStep } from '../../utils/getRouteForOnboardingStep';
import { useAuth } from '../auth/useAuth';

export const useOnboarding = () => {
  const navigate = useNavigate();
  const { refreshUser } = useAuth();

  const { mutateAsync: updateOnboardingStep } =
    trpc.updateOnboardingStep.useMutation();

  const handleUpdateOnboardingStep = async (step: OnboardingStep) => {
    try {
      const nextOnboardingStep = await updateOnboardingStep({
        // TODO: prisma typings
        onboarding_step: step,
      });
      refreshUser();
      navigate(getRouteForOnboardingStep(nextOnboardingStep as OnboardingStep));
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    setOnboardingStep: handleUpdateOnboardingStep,
  };
};
