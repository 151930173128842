import { Tag } from '@chakra-ui/react';

type Props = {
  backlogStatus?: string;
  backloggedUntil?: Date;
};

export const BacklogStatus = ({ backlogStatus, backloggedUntil }: Props) => {
  if (!backlogStatus || backlogStatus === 'None') return null;

  if (backlogStatus === 'AtRisk') {
    return (
      <Tag size="md" variant="outline" colorScheme="orange" fontWeight="bold">
        Backlog Risk
      </Tag>
    );
  }

  if (backlogStatus === 'Backlogged' && !backloggedUntil) {
    return (
      <Tag size="md" variant="outline" colorScheme="orange" fontWeight="bold">
        Backlogged
      </Tag>
    );
  }

  if (backlogStatus === 'Backlogged' && backloggedUntil) {
    const nextFlightCopy = backloggedUntil
      ? new Date(backloggedUntil!)?.toLocaleDateString()
      : 'TBD';

    return (
      <Tag size="md" variant="outline" colorScheme="orange" fontWeight="bold">
        {`Next Flight: ${nextFlightCopy}`}
      </Tag>
    );
  }

  return null;
};
