import { DimensionUnit, WeightUnit } from '@pelicargo/types';

export const convertInToCm = (val: number) => Number((val * 2.54).toFixed(2));

export const convertCmToIn = (val: number) =>
  Number((val * 0.393701).toFixed(2));

export const convertKgToLb = (val: number) =>
  Number((val * 2.20462).toFixed(2));

export const convertLbToKg = (val: number) =>
  Number((val * 0.453592).toFixed(2));

// This assumes that the weight is in kg
export const ensureWeightUnit = (weight: number, unit: WeightUnit) => {
  if (unit === WeightUnit.kg) {
    return weight;
  }

  return convertKgToLb(weight);
};

// This assumes that the dimension is in cm
export const ensureDimensionUnit = (dimension: number, unit: DimensionUnit) => {
  if (unit === DimensionUnit.cm) {
    return dimension;
  }

  return convertCmToIn(dimension);
};

export const toCents = (number: number) => {
  // Still want to return null or undefined if they're passed
  if (isNaN(number)) return number;

  // But if it's zero, we still want to account for that too
  return Math.round(Number(number) * 100);
};

export const toDollars = (number: number | null) => {
  if (number === null) return number;

  // Still want to return null or undefined if they're passed
  if (isNaN(number)) return number;

  // But if it's zero, we still want to account for that too
  return Number(number) / 100;
};

export const stringToBoolean = (value: string) => {
  switch (value?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;

    default:
      return null;
  }
};
