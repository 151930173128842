import {
  Alert,
  AlertIcon,
  Button,
  Container,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { CargoType, QuoteStatus, VariantType } from '@pelicargo/types';
import { HANDLING_OPTIONS, PACKING_OPTIONS, Show } from '@pelicargo/ui';
import { join, prettyCurrency, prettyDate } from '@pelicargo/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  HiBanknotes,
  HiBolt,
  HiChevronLeft,
  HiOutlineChatBubbleLeft,
  HiOutlineEnvelope,
} from 'react-icons/hi2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BookingDrawer } from '../../components/BookingDrawer';
import { CargoInfoCard } from '../../components/CargoInfoCard';
import { Hint } from '../../components/Hint';
import { Page } from '../../components/Page';
import { QuoteList } from '../../components/QuoteList/QuoteList';
import { QuoteListItem } from '../../components/QuoteList/QuoteListItem';
import { QuoteListingModal } from '../../components/QuoteListingModal';
import { ServiceRequestCard } from '../../components/ServiceRequest';
import { ENV } from '../../config/env';
import { trpc } from '../../config/trpc';
import { useAuth } from '../../hooks/auth/useAuth';
import { useQuotes } from '../../hooks/quotes/useQuotes';
import { useRequest } from '../../hooks/request/useRequest';
import { useFrontChatBoot } from '../../hooks/useFrontChatBoot';
import { API } from '../../utils/apiTypes';
import { formatCargo } from '../../utils/inputFormatters';
import { Calculations } from '../../utils/types';

const DISABLED_QUOTE_STATUSES = [
  QuoteStatus.Pending,
  QuoteStatus.SentToCarrier,
  QuoteStatus.ReadyForQC,
];

type Params = {
  requestId?: string;
};

export const QuoteListing = () => {
  const drawer = useDisclosure();
  const quoteListingModal = useDisclosure();
  const navigate = useNavigate();
  const params: Params = useParams();
  const toast = useToast();
  const { initialize } = useFrontChatBoot(document.body);
  const { user } = useAuth();
  const { state } = useLocation();

  const { data: frontSecret } = trpc.getFrontSecret.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const [request, isLoading] = useRequest(
    Number(params?.requestId),
    state?.request,
  );
  const {
    quotes,
    fastestQuotes,
    cheapestQuotes,
    otherQuotes,
    inProgressQuotes,
  } = useQuotes(Number(params?.requestId));

  const [quote, setQuote] = useState<API.QuoteWithTotalCost>();
  const [selectedQuoteVariant, setSelectedQuoteVariant] = useState<
    number | null
  >(null);

  const sendCustomerQuoteUpdate = trpc.sendCustomerQuoteUpdate.useMutation();

  const isVerified = useMemo(
    () => !!request && !!request?.account?.verified_at,
    [request],
  );

  useEffect(() => {
    const generalQuoteVariant = quote?.quote_variants?.find(
      (quoteVariant) => quoteVariant?.type === VariantType.General,
    );

    // If there is a general quote variant, select it
    // Otherwise, select the first quote variant
    if (generalQuoteVariant) setSelectedQuoteVariant(generalQuoteVariant?.id);
    else setSelectedQuoteVariant(quote?.quote_variants?.[0]?.id);
  }, [quote]);

  const origins = useMemo(() => {
    const airportIds = request?.origin_airports?.map(
      (airport) => airport?.airport_as_origin_id,
    );
    return join(airportIds) ?? '';
  }, [request?.origin_airports]);

  const destinations = useMemo(() => {
    const airportIds = request?.destination_airports?.map(
      (airport) => airport?.airport_as_destination_id,
    );
    return join(airportIds) ?? '';
  }, [request?.destination_airports]);

  const checkStatus = useMemo(() => {
    return quotes?.some((quote) => {
      if (quote?.status === QuoteStatus.Expired) return false;
      return true;
    });
  }, [quotes]);

  const effectiveRate = useMemo(() => {
    if (!quote) return null;
    if (!quote?.quote_variants?.length) return null;
    if (!request || !request?.chargeable_weight) return null;

    const quoteVariantTotalCost = quote?.quote_variants?.map((quoteVariant) => {
      if (!quoteVariant?.line_items?.length) return 0;
      return quoteVariant?.line_items.reduce((a: number, b) => a + b?.cost, 0);
    });

    if (!quoteVariantTotalCost?.length) return null;

    const rate =
      Math.min(...quoteVariantTotalCost) / request?.chargeable_weight;

    return prettyCurrency(rate);
  }, [quote, request]);

  const dateDiffInDays = useCallback((a: Date, b: Date) => {
    const _MS_PER_HOUR = 1000 * 60 * 60;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_HOUR);
  }, []);

  const getQuoteValidity = useCallback(
    (quote: API.QuoteWithTotalCost) => {
      if (!quote?.expires_at) return null;
      return dateDiffInDays(new Date(), new Date(quote.expires_at)) / 24;
    },
    [dateDiffInDays],
  );

  const calculations: Calculations = useMemo(() => {
    // The server has done these calculations for us and manages any nuances between units and between per_piece and per_shipment
    return {
      cw: request?.chargeable_weight,
      vw: request?.volumetric_weight,
      gw: request?.gross_weight,
    };
  }, [
    request?.chargeable_weight,
    request?.volumetric_weight,
    request?.gross_weight,
  ]);

  const handling = useMemo(() => {
    const handling = [];
    if (request?.is_known_shipper) {
      handling.push('Known shipper');
    }
    if (request?.is_pre_screened) {
      handling.push('Screened');
    }

    request?.cargo?.forEach((cargo) => {
      cargo?.handling?.map((option) => {
        handling.push(
          [...HANDLING_OPTIONS, ...PACKING_OPTIONS]?.find(
            (item) => item.value === option,
          )?.label,
        );
      });
    });

    return [...new Set(handling)];
  }, [request?.cargo, request?.is_known_shipper, request?.is_pre_screened]);

  const onToggleDetails = useCallback(
    (quote: API.QuoteWithTotalCost) => {
      if (DISABLED_QUOTE_STATUSES.includes(quote.status)) return;
      setQuote(quote);
      drawer.onOpen();
    },
    [drawer],
  );

  const handleSupport = useCallback(() => {
    drawer.onClose();

    if (!initialize || !frontSecret) return;

    initialize({
      chatId: ENV.FRONT_CHAT_ID,
      token: frontSecret,
      email: user?.email,
      name: user?.full_name,
      requestId: params?.requestId,
      useDefaultLauncher: true,
    });
  }, [drawer, initialize, frontSecret, user, params?.requestId]);

  const handleEmailRequest = async () => {
    try {
      await sendCustomerQuoteUpdate.mutateAsync({
        requestId: Number(params?.requestId),
      });

      toast({
        title: 'Success',
        description: 'An email has been sent to you successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error?.message,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const validity = getQuoteValidity(quote);
  const flightPath = quote?.flight_path ? quote?.flight_path?.length - 1 : -1;
  const hasQuotesInProgress = quotes?.some(
    (quote) => quote?.status === QuoteStatus.InProgress,
  );

  return (
    <Page title="Quote" flex="1">
      <Container w="full" maxW="container.xl" py={{ base: '8', lg: '16' }}>
        <Stack spacing="8">
          <Stack w="full" spacing="8">
            <Flex>
              <Button
                leftIcon={<HiChevronLeft />}
                onClick={() => navigate('/requests')}
              >
                Requests
              </Button>
            </Flex>
            <Stack
              spacing={{ base: '8', lg: '0' }}
              direction="row"
              align="center"
              justify="space-between"
              wrap="wrap"
            >
              <Stack spacing="0">
                <Heading fontSize="4xl" display="inline">
                  <Skeleton w="10" h="8" display="inline" isLoaded={!!origins}>
                    {origins}
                  </Skeleton>{' '}
                  →{' '}
                  <Skeleton
                    w="10"
                    h="8"
                    display="inline"
                    isLoaded={!!destinations}
                  >
                    {destinations}
                  </Skeleton>
                </Heading>
                <Text fontSize="2xl" display="inline">
                  Request#{' '}
                  <Skeleton isLoaded={!!request?.id} display="inline">
                    {`R${request?.id}`}
                  </Skeleton>
                </Text>
              </Stack>

              <Show if={checkStatus === true}>
                <Stack direction="row" align="center">
                  <Button
                    size="md"
                    isLoading={sendCustomerQuoteUpdate.isLoading}
                    leftIcon={<HiOutlineEnvelope />}
                    onClick={handleEmailRequest}
                  >
                    Email Me
                  </Button>
                  <Button
                    size="md"
                    leftIcon={<HiOutlineChatBubbleLeft />}
                    onClick={handleSupport}
                  >
                    Contact Support
                  </Button>
                </Stack>
              </Show>
            </Stack>
          </Stack>

          <Stack direction={{ base: 'column', lg: 'row' }} spacing="8">
            <Stack w="full">
              <CargoInfoCard
                onOpen={quoteListingModal.onOpen}
                cargoType={request?.cargo_type as CargoType}
                grossWeight={calculations?.gw}
                volumetricWeight={calculations?.vw}
                chargeableWeight={calculations?.cw}
                categories={handling}
              />
            </Stack>
            <Stack w="full">
              <ServiceRequestCard
                flight={
                  request?.requires_direct_flight === false ? 'Any' : 'Direct'
                }
                aircrafts={request?.aircraft_types as any[]}
                product={
                  request?.service_type ? request?.service_type?.join(', ') : ''
                }
              />
            </Stack>
          </Stack>

          <Show if={!isVerified && !isLoading}>
            <Hint name="quoteListing-requestSummary-unverified">
              <Alert
                colorScheme="primary"
                variant="top-accent"
                justifyContent="center"
              >
                <Text>
                  Your company is currently unverified. We will be in touch with
                  you to verify your information before displaying rates. Please{' '}
                  <Button
                    variant="link"
                    colorScheme="primary"
                    onClick={handleSupport}
                  >
                    contact us
                  </Button>{' '}
                  with any questions.
                </Text>
              </Alert>
            </Hint>
          </Show>
          <Show if={!hasQuotesInProgress && isVerified && !isLoading}>
            <Hint name="quoteListing-requestSummary-verified">
              <Alert
                variant="top-accent"
                status={isVerified ? 'info' : 'warning'}
                justifyContent="center"
              >
                <AlertIcon />
                We are sourcing your deals and will email you when they are
                published
              </Alert>
            </Hint>
          </Show>
        </Stack>

        <Stack spacing="8" pt="8">
          <Show if={!quotes?.length}>
            <Flex direction="column">
              <QuoteListItem
                flightSegments={[
                  ...(request?.origin_airports || []),
                  ...(request?.destination_airports || []),
                ]}
              />
              <QuoteListItem
                flightSegments={[
                  ...(request?.origin_airports || []),
                  ...(request?.destination_airports || []),
                ]}
              />
              <QuoteListItem
                flightSegments={[
                  ...(request?.origin_airports || []),
                  ...(request?.destination_airports || []),
                ]}
              />
            </Flex>
          </Show>
          <QuoteList
            title="FASTEST"
            quotes={fastestQuotes}
            chargeableWeight={request?.chargeable_weight}
            icon={HiBolt}
            onToggleDetails={onToggleDetails}
          />
          <QuoteList
            title="CHEAPEST"
            quotes={cheapestQuotes}
            chargeableWeight={request?.chargeable_weight}
            icon={HiBanknotes}
            onToggleDetails={onToggleDetails}
          />
          <QuoteList
            title="OTHERS"
            quotes={otherQuotes}
            chargeableWeight={request?.chargeable_weight}
            showSort={true}
            onToggleDetails={onToggleDetails}
          />
          <QuoteList
            title="IN PROGRESS"
            quotes={inProgressQuotes}
            chargeableWeight={request?.chargeable_weight}
            onToggleDetails={onToggleDetails}
          />
        </Stack>
      </Container>
      <BookingDrawer
        isOpen={drawer.isOpen}
        onClose={drawer.onClose}
        flightPath={flightPath}
        quote={quote}
        effectiveRate={effectiveRate}
        request={request}
        validity={validity}
        selectedQuoteVariant={selectedQuoteVariant}
        setSelectedQuoteVariant={setSelectedQuoteVariant}
        handleSupport={handleSupport}
      />

      <QuoteListingModal
        isOpen={quoteListingModal.isOpen}
        onClose={quoteListingModal.onClose}
        origin={origins}
        destination={destinations}
        cargo={request?.cargo?.map((c: any) => formatCargo(c)) as any}
        dropoffBy={prettyDate(request?.dropoff_by)}
        cargoType={request?.cargo_type as CargoType}
        cargoDetails={request?.cargo_details}
        grossWeight={calculations?.gw}
        volumetricWeight={calculations?.vw}
        chargeableWeight={calculations?.cw}
      />
    </Page>
  );
};
