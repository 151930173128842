import {
  AircraftType,
  DimensionUnit,
  Hint,
  MaxAircraftParams,
  WeightUnit,
} from '@pelicargo/types';

export type options = Record<string, string>;

export const METERS_TO_MILES = 0.000621371;

// We export here so we don't have to import full types pkg into the api
export type Units = {
  weightUnit: WeightUnit;
  dimensionUnit: DimensionUnit;
};

// All max params are in cm and kg
export const MAX_WIDE_BODY_PARAMS: MaxAircraftParams = {
  length: 396.24,
  width: 243.84,
  height: 162.56,
  weight_per_piece: 10000,
};
export const MAX_FREIGHTER_PARAMS: MaxAircraftParams = {
  length: 7000,
  width: 381,
  height: 304,
  weight_per_piece: 250000,
};
export const MAX_NARROW_BODY_PARAMS: MaxAircraftParams = {
  length: 121.92,
  width: 101.6,
  height: 78.74,
  weight_per_piece: 150,
};
export const MAX_RFS_PARAMS: MaxAircraftParams = {
  length: Number.MAX_SAFE_INTEGER,
  width: Number.MAX_SAFE_INTEGER,
  height: Number.MAX_SAFE_INTEGER,
  weight_per_piece: Number.MAX_SAFE_INTEGER,
};

export const MAX_PARAMS = {
  [AircraftType.Freighter]: MAX_FREIGHTER_PARAMS,
  [AircraftType.WideBody]: MAX_WIDE_BODY_PARAMS,
  [AircraftType.NarrowBody]: MAX_NARROW_BODY_PARAMS,
  [AircraftType.RFS]: MAX_RFS_PARAMS,
};

/**
 * Hints are used to provide a quick overview of the page or feature.
 *
 * Names are determined by the page and the feature and it's variant, for example:
 * [page]-[feature]-[variant]
 * quoteListing-requestSummary-unverified
 *
 * Or if there's no variant:
 * home-requestForm
 */
export const HINTS: Hint[] = [
  {
    name: 'home-requestForm',
    displayed_at: null,
    content: {
      title: 'Welcome to Pelicargo!',
      description:
        "This is where you will begin to request a quote for your shipment. We ask that you enter in as many details as possible including type of cargo, dimensions and weight. Once completed, click on 'Search Deals' to continue!",
    },
  },
  {
    name: 'home-dangerousGoodsForm',
    displayed_at: null,
    content: {
      title: 'Dangerous Goods',
      description:
        'If you have your DGD document, please upload it here, otherwise click on “Upload MSDS/Manual Input” and you can enter in the information you have now.\n\nYou will have the opportunity to upload the DGD document prior to confirming your booking!',
    },
  },
  {
    name: 'requestQuote-requestSummary',
    displayed_at: null,
    content: {
      title: 'Request Summary',
      description:
        'Here you can see a summary of your request details. There are also optional additional fields for any details you wish to provide. Target rates are helpful on confirmed shipments. Select "Request Quotes" to continue.',
    },
  },
  {
    name: 'quoteListing-requestSummary-unverified',
    displayed_at: null,
    content: {
      title: 'Quotes',
      description:
        'Depending on the shipment details and once your account is verified, quotes may be immediately available to be booked or need time for carrier validation. However our Pelicargo service team will always source the best quote for you!',
    },
  },
  {
    name: 'quoteListing-requestSummary-verified',
    displayed_at: null,
    content: {
      title: 'Quotes',
      description:
        'Depending on the information entered, some quotes are immediately available to be booked, however our Pelicargo engine will continue to source the best quote for you!',
    },
  },
  {
    name: 'quoteListing-quoteVariants',
    displayed_at: null,
    content: {
      title: 'Service Levels',
      description:
        'Here you can see the available Service Levels for your request as well as the breakdown for fees and surcharges.',
    },
  },
  {
    name: 'quoteConfirmation-costSummary',
    displayed_at: null,
    content: {
      title: 'Booking Complete!',
      description:
        "With your quote variant selected you can see a summary of the transit including length, transport type and drop-off details. From here, our Pelicargo team will followup via email to confirm the booking.\n\nIf you wish to start a new request, please click the logo on the top left of the screen to return to the start page - don't forget to bookmark that page!",
    },
  },
];
