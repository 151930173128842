import { Box, Divider, SkeletonText, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';

type Props = {
  origin?: string;
  estimatedTransitTime?: string;
  destination?: string;
};

export const SingleFlightPath = ({
  origin,
  estimatedTransitTime,
  destination,
}: Props) => {
  return (
    <Stack
      direction="row"
      flex="1"
      align="center"
      justify="center"
      w="full"
      spacing="4"
      maxW="sm"
    >
      <SkeletonText noOfLines={1} w="10" isLoaded={!!origin}>
        <Text>{origin}</Text>
      </SkeletonText>
      <Show if={!!estimatedTransitTime}>
        <Stack align="center" spacing="0" w="full" position="relative">
          <Divider minW="200px" borderWidth="2px" />
          <Box position="absolute" mx="auto" top="2">
            <Text fontSize="sm" fontWeight="semibold">
              {estimatedTransitTime}
            </Text>
          </Box>
        </Stack>
      </Show>
      <SkeletonText noOfLines={1} w="10" isLoaded={!!destination}>
        <Text>{destination}</Text>
      </SkeletonText>
    </Stack>
  );
};
