import {
  Card,
  CardBody,
  Flex,
  Hide,
  IconButton,
  Image,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  AircraftType,
  FlightSegment,
  LineItemType,
  QuoteStatus,
} from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  getPrettyAircraftType,
  getPrettyEstimatedTransitTime,
  prettyCurrency,
  quoteTotalCost,
} from '@pelicargo/utils';
import { differenceInCalendarDays } from 'date-fns';
import { compact, uniq } from 'lodash';
import { memo, useMemo } from 'react';
import { HiChevronRight } from 'react-icons/hi2';

import { API } from '../../utils/apiTypes';
import { BacklogStatus } from '../BacklogStatus';
import { LabeledValue } from '../LabeledValue';
import { SingleFlightPath } from '../SingleFlightPath';

type Props = {
  quote?: API.RequestQuote;
  airline?: API.Airline;
  flightSegments?: FlightSegment[];
  chargeableWeight?: number;

  isFirst?: boolean;
  isLast?: boolean;

  onViewDetails?: () => void;
};

const getAircraftType = (flightSegments: FlightSegment[]): AircraftType => {
  const flightTypes = uniq(
    compact(flightSegments?.map((path) => path?.aircraft_type)),
  );

  const aircraftPriority = [
    AircraftType.RFS,
    AircraftType.NarrowBody,
    AircraftType.WideBody,
    AircraftType.Freighter,
  ];

  for (const type of aircraftPriority) {
    if (flightTypes.includes(type)) return AircraftType[type];
  }

  return;
};

const getQuoteVariantCost = (quoteVariant: API.QuoteVariant) => {
  if (!quoteVariant?.line_items?.length) return 0;
  return quoteVariant?.line_items?.reduce((a: number, b) => a + b?.cost, 0);
};

const getQuoteCheapestRate = (
  quote: API.RequestQuote,
  chargeableWeight: API.ChargeableWeight,
) => {
  const hasNoQuoteVariants = !quote?.quote_variants?.length;

  if (hasNoQuoteVariants || !chargeableWeight) return;

  const quoteVariantTotalCosts =
    quote?.quote_variants?.map(getQuoteVariantCost);
  if (!quoteVariantTotalCosts?.length) return;

  return Math.min(...quoteVariantTotalCosts) / chargeableWeight;
};

export const QuoteListItem = memo(
  ({
    quote,
    airline,
    flightSegments,
    chargeableWeight,
    onViewDetails,
  }: Props) => {
    const hasDetailsEnabled = useMemo(() => {
      const hasStatus = quote?.status;
      const hasStatusThatIsNotReadyForDetails = [
        QuoteStatus.Expired,
        QuoteStatus.Pending,
        QuoteStatus.ReadyForQC,
        QuoteStatus.SentToCarrier,
      ].includes(quote?.status);

      return hasStatus && !hasStatusThatIsNotReadyForDetails;
    }, [quote?.status]);

    const stopCount = useMemo(
      () => flightSegments?.length - 1,
      [flightSegments],
    );
    const totalCost = useMemo(() => quoteTotalCost(quote), [quote]);
    const cheapestRate = useMemo(
      () => getQuoteCheapestRate(quote, chargeableWeight),
      [chargeableWeight, quote],
    );
    const isExpired = useMemo(
      () => quote?.status === QuoteStatus.Expired,
      [quote?.status],
    );

    const aircraftType = useMemo(
      () => getAircraftType(flightSegments),
      [flightSegments],
    );

    const validityInDays = useMemo(() => {
      if (!quote?.expires_at) return;

      return differenceInCalendarDays(new Date(quote?.expires_at), new Date());
    }, [quote?.expires_at]);

    const { originAirportCode, destinationAirportCode } = useMemo(() => {
      const firstLeg = flightSegments?.[0];
      const lastLeg = flightSegments?.[flightSegments?.length - 1];

      const originAirportCode =
        firstLeg?.origin_airport_id ||
        quote?.origin_airport_id ||
        firstLeg?.airport_as_origin_id;

      const destinationAirportCode =
        lastLeg?.destination_airport_id ||
        quote?.destination_airport_id ||
        lastLeg?.airport_as_destination_id;

      return { originAirportCode, destinationAirportCode };
    }, [
      flightSegments,
      quote?.origin_airport_id,
      quote?.destination_airport_id,
    ]);

    const isSpotRate = useMemo(() => {
      if (!quote?.quote_variants?.length) return false;
      // Loop through each quote variant
      for (const variant of quote.quote_variants) {
        if (!variant?.line_items?.length) return false;
        // Loop through each line item in the current variant
        for (const lineItem of variant.line_items) {
          // Check if the line item's type is "SPOT"
          return lineItem?.type === LineItemType.SPOT;
        }
      }
      return false;
    }, [quote]);

    return (
      <Card
        cursor={hasDetailsEnabled ? 'pointer' : 'initial'}
        transition="all 200ms ease"
        shadow="none"
        px="0"
        borderRadius="none"
        borderBottomWidth="0"
        _first={{ borderTopRadius: 'md' }}
        _last={{ borderBottomRadius: 'md', borderBottomWidth: '1px' }}
        _hover={{
          shadow: hasDetailsEnabled ? 'xl' : 'none',
          transform: hasDetailsEnabled ? 'scale3d(1.025, 1.025, 1.025)' : '',
        }}
        onClick={onViewDetails}
      >
        <CardBody>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            align={{ base: 'flex-start', lg: 'center' }}
            justify="space-between"
            w="full"
            spacing="8"
          >
            <Flex flex="0.5" justify="flex-start" w="full">
              <Stack
                direction="row"
                align="center"
                spacing="8"
                flex="1"
                justify="flex-start"
              >
                <Skeleton isLoaded={!!airline}>
                  <Image
                    src={airline?.logo_uri}
                    height={37}
                    width={37}
                    alt={airline?.name}
                  />
                </Skeleton>

                <Stack>
                  <SkeletonText
                    isLoaded={!!airline}
                    noOfLines={1}
                    w={!airline ? '20' : 'full'}
                  >
                    <Text fontWeight="semibold">{airline?.name}</Text>
                  </SkeletonText>
                  <Show if={!!airline}>
                    <Wrap>
                      <Show if={stopCount === 0}>
                        <WrapItem>
                          <Tag
                            variant="outline"
                            colorScheme="teal"
                            fontWeight="bold"
                            size="md"
                          >
                            Direct
                          </Tag>
                        </WrapItem>
                      </Show>
                      <Show if={stopCount >= 1}>
                        <WrapItem>
                          <Tag
                            variant="outline"
                            colorScheme="orange"
                            fontWeight="bold"
                            size="md"
                          >
                            {stopCount} {stopCount === 1 ? 'stop' : 'stops'}
                          </Tag>
                        </WrapItem>
                      </Show>
                      <WrapItem>
                        <BacklogStatus
                          backlogStatus={quote?.backlog_status}
                          backloggedUntil={quote?.backlogged_until}
                        />
                      </WrapItem>
                    </Wrap>
                  </Show>
                </Stack>
              </Stack>
              <Hide above="md">
                <Stack direction="row" align="center" spacing="4">
                  <Show if={isSpotRate}>
                    <Tag
                      variant="subtle"
                      colorScheme="blue"
                      fontWeight="bold"
                      size="md"
                    >
                      Deal
                    </Tag>
                  </Show>
                  <IconButton
                    aria-label=""
                    colorScheme="primary"
                    rounded="full"
                    w="fit-content"
                    isDisabled={!hasDetailsEnabled}
                    visibility={hasDetailsEnabled ? 'visible' : 'hidden'}
                    icon={<HiChevronRight />}
                    onClick={onViewDetails}
                  />
                </Stack>
              </Hide>
            </Flex>

            <SingleFlightPath
              origin={originAirportCode}
              estimatedTransitTime={
                getPrettyEstimatedTransitTime(quote?.estimated_transit_time) ||
                '-'
              }
              destination={destinationAirportCode}
            />

            <Flex
              display={{ base: '', lg: 'flex' }}
              flex="1"
              justify="flex-end"
              w="full"
            >
              <SimpleGrid
                columns={{ base: 2, lg: 5 }}
                gap={4}
                justifyItems={{ base: 'flex-start', lg: 'flex-end' }}
                w="full"
              >
                <LabeledValue
                  spacing="0"
                  minW="20"
                  label="Aircraft"
                  value={
                    aircraftType ? getPrettyAircraftType(aircraftType) : '-'
                  }
                />
                <LabeledValue
                  spacing="0"
                  minW="20"
                  label="Rate"
                  value={
                    cheapestRate ? `${prettyCurrency(cheapestRate)}/kg` : '-'
                  }
                />
                <LabeledValue
                  spacing="0"
                  minW="20"
                  label="Validity"
                  value={
                    validityInDays <= 0
                      ? 'Expired'
                      : validityInDays
                        ? `${validityInDays} Days`
                        : '-'
                  }
                />
                <LabeledValue
                  spacing="0"
                  minW="20"
                  label={isExpired ? 'Expired' : 'Total'}
                  value={!totalCost ? '-' : prettyCurrency(totalCost)}
                />

                <Hide below="md">
                  <Stack direction="row" align="center" spacing="4">
                    <Show if={isSpotRate}>
                      <Tag
                        variant="subtle"
                        colorScheme="blue"
                        fontWeight="bold"
                        size="sm"
                      >
                        Deal
                      </Tag>
                    </Show>
                    <IconButton
                      aria-label=""
                      colorScheme="primary"
                      rounded="full"
                      w="fit-content"
                      isDisabled={!hasDetailsEnabled}
                      visibility={hasDetailsEnabled ? 'visible' : 'hidden'}
                      icon={<HiChevronRight />}
                      onClick={onViewDetails}
                    />
                  </Stack>
                </Hide>
              </SimpleGrid>
            </Flex>
          </Stack>
        </CardBody>
      </Card>
    );
  },
);
