import {
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { AircraftType } from '@pelicargo/types';
import { getPrettyAircraftType } from '@pelicargo/utils';

import { LabeledValue } from '../LabeledValue';

export type Props = {
  flight: string;
  aircrafts: AircraftType[];
  product: string;
};

export const ServiceRequestCard = ({
  aircrafts = [],
  flight,
  product,
}: Props) => {
  return (
    <Card>
      <CardBody>
        <Stack spacing="6">
          <Heading size="md">Service Request</Heading>
          <SimpleGrid columns={4}>
            <LabeledValue label="Flight" value={flight} />
            <LabeledValue
              label="Product"
              value={product}
              tooltip={
                <Stack spacing="0" w="full">
                  <Text>
                    <strong>Expedited</strong>: priority or next-flight out
                    service.
                  </Text>
                  <Text>
                    <strong>General</strong>: normal service level.
                  </Text>
                </Stack>
              }
            />
          </SimpleGrid>

          <Stack direction="row">
            {aircrafts?.map((aircraft) => (
              <Tag key={aircraft} size="lg">
                {getPrettyAircraftType(aircraft)}
              </Tag>
            ))}
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};
